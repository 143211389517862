<template>
  <Page>
    <div class="home-page">
      <zui-header/>
      <div class="login-box" :style="{ height: height }">
        <img src="https://www.achencybe.com/img/pc/login/5-1.jpg" style="width: 600px"/>
        <div class="form-box">
          <img src="https://www.achencybe.com/img/pc/logo_1.png"/>
          <div class="text-box" style="margin-top: -20px">
            <div class="title">{{ $t('login_and_register_page.login') }}</div>
            <div>{{ $t('login_and_register_page.welcome_back') }}</div>
          </div>
          <div class="tabs-box">
            <span @click="active = 0" :class="{ active: active === 0 }"
            >{{ $t('login_and_register_page.phone_login') }}</span
            >
            <span class="border-line" style="left: 25%"></span>
          </div>

          <el-form
              ref="phoneForm"
              key="phoneForm"
              :rules="phoneRules"
              :model="phoneForm"
              style="width: 330px"
              v-if="active === 0"
          >
            <el-form-item prop="phone">


              <zui-country-phone :sendSms= "true"
                                 v-model="phoneForm.phone"
                                 :placeholder="$t('register_page.login_phone_placeholder')"
                                 v-on:listenToChildEvent="showRealNumber"
                                 v-on:listenSendSms="takeCode"
                                 :limit-seconds = seconds
              ></zui-country-phone>
<!--              <el-input v-model="phoneForm.phone" :placeholder="$t('register_page.login_phone_placeholder')">-->
<!--                <template slot="append">-->
<!--                  <span v-show="seconds != 0">{{$t('register_page.retry_begin')}}{{ seconds }}{{$t('register_page.retry_end')}}</span>-->
<!--                </template>-->
<!--              </el-input>-->
<!--              <el-button class="append" v-show="seconds == 0" @click="takeCode"-->
<!--              >{{$t('login_and_register_page.verification_code')}}-->
<!--              </el-button-->
<!--              >-->
            </el-form-item>
            <el-form-item prop="vcode">
              <el-input
                  v-model="phoneForm.vcode"
                  :placeholder="$t('login_and_register_page.verification_code_placeholder')"
              ></el-input>
            </el-form-item>
            <el-form-item prop="status">
              <silder-verify
                  key="phoneVerify"
                  @success="handleSuccess('phoneForm')"
              ></silder-verify>
            </el-form-item>
            <!--            <el-form-item>-->
            <!--              <el-checkbox v-model="phoneForm.subscribe"-->
            <!--                >同意并愿意遵守Achen&Cybe-->
            <!--                <el-link-->
            <!--                  @click.native.stop="$router.push({ name: 'clausePage' })"-->
            <!--                  style="margin-top:-5px;"-->
            <!--                  >使用条款</el-link-->
            <!--                ></el-checkbox-->
            <!--              >-->
            <!--            </el-form-item>-->
            <el-form-item>
              <el-button
                  type="primary"
                  style="width: 100%"
                  @click="submitForm('phoneForm')"
              >{{ $t('login_and_register_page.login') }}
              </el-button
              >
              <br/>

              <el-link
                  type="primary"
                  :underline="false"
                  @click="$router.push({ name: 'RegisteredPage' })"
              >{{ $t('login_page.sign_up_button') }}
              </el-link
              >
            </el-form-item>
          </el-form>
        </div>
      </div>
    </div>
  </Page>
</template>
<script>
import "../../less/home-page.less";
import Page from "../../../components/Page";
import ZuiHeader from "../../../components/Header";
import Util from "../../../Util";
import SilderVerify from "@/components/SilderVerify";
import ZuiCountryPhone from "@/components/CountryPhone.vue";

export default {
  components: {ZuiCountryPhone, Page, ZuiHeader, SilderVerify},
  computed: {
    phoneRules() {
      const validatestatus = (rule, value, callback) => {
        if (!value) {
          callback(new Error(window.vm.$t('login_and_register_page.slide')));
        } else {
          callback();
        }
      };
      return {
        phone: [
          {required: true, message: window.vm.$t('login_and_register_page.input_phone'), trigger: "blur"},
          {
            pattern: this.realNumber.startsWith('+86') ? /^1[3-9]\d{9}$/  : /\d+/,
            message: window.vm.$t('login_and_register_page.input_phone_uncheck'),
            trigger: "blur",
          },
        ],
        vcode: [{required: true, message: window.vm.$t('login_and_register_page.input_verification_code'), trigger: "blur"}],
        status: [{validator: validatestatus, trigger: "change"}],
      }
    },
  },
  data() {
    return {
      realNumber: '',
      height: "",
      active: 0,
      seconds: 0,
      phoneForm: {
        realNumber: '',
        phone: "",
        vcode: "",
        status: "",
      },

    };
  },
  mounted() {
    this.height = window.innerHeight + "px";
  },
  methods: {
    takeCode(number) {
      console.log(number)
      this.$refs.phoneForm.validateField("phone", (valid) => {
        if (!valid) {
          this.$api
              .sendCode({
                phone: this.phoneForm.realNumber,
                type: 0,
              })
              .then((res) => {
                if (res.code === 0) {
                  this.$message.success(window.vm.$t('login_and_register_page.send_success'));
                  this.seconds = 60;
                  let timer = setInterval(() => {
                    this.seconds = this.seconds - 1;
                    if (this.seconds === 0) {
                      clearInterval(timer);
                    }
                  }, 1000);
                }
              });
        }
      });
    },
    showRealNumber(number) {
      this.realNumber = number
      this.phoneForm.realNumber = number;
      if (number.startsWith('+86')) {
        number = number.substring(3);
      }
      //console.log(this.realNumber)
    },
    handleSuccess(formName) {
      this[formName].status = 1;
      this.$refs[formName].validateField("status");
    },
    submitForm(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          this.$api
              .loginByPhone(this[formName])
              .then((res) => {
                if (res.code === 0) {
                  this.$store.commit("setTicket", res.data.ticket);
                  return this.$api.getUserId({ticket: res.data.ticket});
                }
              })
              .then((res) => {
                if (res.code === 0) {
                  return this.$api.getClientInfo({id: res.data.userId});
                }
              })
              .then((res) => {
                if (res.code === 0) {
                  this.$store.commit("setUserInfo", res.data);
                  this.$message({
                    type: "success",
                    message: window.vm.$t('login_and_register_page.login_success'),
                    onClose: () => {
                      if (window.sessionStorage.getItem("isCompletion")) {
                        let query = JSON.parse(window.sessionStorage.getItem("isCompletion"));
                        this.$router.push({
                          name: "OrderPage",
                          query: query,
                        });
                      } else {
                        this.$router.push("/");
                      }
                    },
                  });
                }
              });
        } else {
          console.log("error submit!!");
          return false;
        }
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.login-box {
  display: flex;
  justify-content: center;
  align-items: center;

  .form-box {
    width: 500px;
    height: 722px;
    background-color: #fff;
    padding-top: 60px;
    text-align: center;

    ::v-deep .el-input-group--append .el-input__inner {
      border-right: 0px !important;
    }

    ::v-deep .el-input-group__append {
      background-color: #fff !important;
      border-left: 0px !important;
    }

    .append {
      position: absolute;
      bottom: 4px;
      border: 0px;
      right: 1px;
      height: 34px;

      &:hover {
        color: #409eff;
        background-color: #fff;
      }
    }

    img {
      width: 240px;
    }

    .text-box {
      width: 330px;
      text-align: center;
      margin: 0 auto;

      .title {
        margin-top: 60px;
        font-size: 30px;
      }

      .sub-title {
        font-size: 12px;
      }
    }

    .tabs-box {
      height: 50px;
      line-height: 50px;
      width: 330px;
      margin: 30px auto 0;
      display: flex;
      justify-content: center;
      align-items: center;
      border-bottom: 1px solid #eee;
      position: relative;

      span {
        width: 50%;
        cursor: pointer;

        &.active {
          color: #4aabf2;
        }
      }

      .border-line {
        position: absolute;
        width: 50%;
        bottom: 0;
        height: 2px;
        z-index: 2;
        background-color: #4aabf2;
        transition: left 0.3s;
      }
    }

    .el-form {
      margin: 40px auto 0;
    }
  }
}
</style>