var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "silder-range", class: _vm.rangeStatus ? "success" : "" },
    [
      _c("i", {
        class: _vm.rangeStatus ? _vm.successIcon : _vm.startIcon,
        on: { mousedown: _vm.rangeMove, touchstart: _vm.rangeMove },
      }),
      _vm._v(
        " " + _vm._s(_vm.rangeStatus ? _vm.successText : _vm.startText) + " "
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }