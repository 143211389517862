var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("Page", [
    _c(
      "div",
      { staticClass: "home-page" },
      [
        _c("zui-header"),
        _c("div", { staticClass: "login-box", style: { height: _vm.height } }, [
          _c("img", {
            staticStyle: { width: "600px" },
            attrs: { src: "https://www.achencybe.com/img/pc/login/5-1.jpg" },
          }),
          _c(
            "div",
            { staticClass: "form-box" },
            [
              _c("img", {
                attrs: { src: "https://www.achencybe.com/img/pc/logo_1.png" },
              }),
              _c(
                "div",
                {
                  staticClass: "text-box",
                  staticStyle: { "margin-top": "-20px" },
                },
                [
                  _c("div", { staticClass: "title" }, [
                    _vm._v(_vm._s(_vm.$t("login_and_register_page.login"))),
                  ]),
                  _c("div", [
                    _vm._v(
                      _vm._s(_vm.$t("login_and_register_page.welcome_back"))
                    ),
                  ]),
                ]
              ),
              _c("div", { staticClass: "tabs-box" }, [
                _c(
                  "span",
                  {
                    class: { active: _vm.active === 0 },
                    on: {
                      click: function ($event) {
                        _vm.active = 0
                      },
                    },
                  },
                  [
                    _vm._v(
                      _vm._s(_vm.$t("login_and_register_page.phone_login"))
                    ),
                  ]
                ),
                _c("span", {
                  staticClass: "border-line",
                  staticStyle: { left: "25%" },
                }),
              ]),
              _vm.active === 0
                ? _c(
                    "el-form",
                    {
                      key: "phoneForm",
                      ref: "phoneForm",
                      staticStyle: { width: "330px" },
                      attrs: { rules: _vm.phoneRules, model: _vm.phoneForm },
                    },
                    [
                      _c(
                        "el-form-item",
                        { attrs: { prop: "phone" } },
                        [
                          _c("zui-country-phone", {
                            attrs: {
                              sendSms: true,
                              placeholder: _vm.$t(
                                "register_page.login_phone_placeholder"
                              ),
                              "limit-seconds": _vm.seconds,
                            },
                            on: {
                              listenToChildEvent: _vm.showRealNumber,
                              listenSendSms: _vm.takeCode,
                            },
                            model: {
                              value: _vm.phoneForm.phone,
                              callback: function ($$v) {
                                _vm.$set(_vm.phoneForm, "phone", $$v)
                              },
                              expression: "phoneForm.phone",
                            },
                          }),
                        ],
                        1
                      ),
                      _c(
                        "el-form-item",
                        { attrs: { prop: "vcode" } },
                        [
                          _c("el-input", {
                            attrs: {
                              placeholder: _vm.$t(
                                "login_and_register_page.verification_code_placeholder"
                              ),
                            },
                            model: {
                              value: _vm.phoneForm.vcode,
                              callback: function ($$v) {
                                _vm.$set(_vm.phoneForm, "vcode", $$v)
                              },
                              expression: "phoneForm.vcode",
                            },
                          }),
                        ],
                        1
                      ),
                      _c(
                        "el-form-item",
                        { attrs: { prop: "status" } },
                        [
                          _c("silder-verify", {
                            key: "phoneVerify",
                            on: {
                              success: function ($event) {
                                return _vm.handleSuccess("phoneForm")
                              },
                            },
                          }),
                        ],
                        1
                      ),
                      _c(
                        "el-form-item",
                        [
                          _c(
                            "el-button",
                            {
                              staticStyle: { width: "100%" },
                              attrs: { type: "primary" },
                              on: {
                                click: function ($event) {
                                  return _vm.submitForm("phoneForm")
                                },
                              },
                            },
                            [
                              _vm._v(
                                _vm._s(
                                  _vm.$t("login_and_register_page.login")
                                ) + " "
                              ),
                            ]
                          ),
                          _c("br"),
                          _c(
                            "el-link",
                            {
                              attrs: { type: "primary", underline: false },
                              on: {
                                click: function ($event) {
                                  return _vm.$router.push({
                                    name: "RegisteredPage",
                                  })
                                },
                              },
                            },
                            [
                              _vm._v(
                                _vm._s(_vm.$t("login_page.sign_up_button")) +
                                  " "
                              ),
                            ]
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  )
                : _vm._e(),
            ],
            1
          ),
        ]),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }